@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker__input-container input {
  @apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 focus:border-emerald-500 focus:outline-none;
}

.react-datepicker__tab-loop {
  @apply absolute;
}

.react-datepicker-popper {
  @apply absolute top-20 z-10 left-0 w-72 text-sm transform-none bg-white shadow px-3 py-2;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-800 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-50 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400;
}

.react-datepicker__day--in-range {
  @apply bg-emerald-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-emerald-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-emerald-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-emerald-500;
}

.react-datepicker__day--selected {
  @apply bg-emerald-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-emerald-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-emerald-500 text-white hover:text-gray-700 hover:bg-white;
}
.loading-spinner {
  border-top-color: #5db075;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.scrollBar {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.scrollBar::-webkit-scrollbar {
  width: 7px;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: #d4d4d8;
  border-radius: 5px;
}

.scrollBar::-webkit-scrollbar-thumb:hover {
  background: #a1a1aa;
}
/* Track */
.scrollBar::-webkit-scrollbar-track {
  background: #ffffff;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
